import { useRef, useState } from "react";
import { Button } from "../../components";
import { success } from "../../components/Toast";
import { put } from "../../utils/api";
import { DeviceContainer } from "./modals/DeviceContainer";
import { BurninTable, TableEmpty, Td, Tr } from "./styles";
import { IDevices, InspectionStepsEnum } from "./types";
import { FaExchangeAlt, FaFile } from "react-icons/fa";

type PreBurninInspectionProps = {
  devices: IDevices[];
  inspectionId: string;
  refresh: () => void;
};

export default function PreBurninInspection({
  devices,
  inspectionId,
  refresh,
}: PreBurninInspectionProps) {
  const getFormattedStatus = (details: any, key: string) => {
    if (!details) return "-";
    return details[key] ? "SIM" : "NÃO";
  };

  const [isProcessing, setIsProcessing] = useState(false);
  const alterStatusButtonRefs = useRef<any[]>([]);

  const handleDeactivateDevice = (
    qualityInspectionId: number,
    deviceId: number
  ) => {
    setIsProcessing(true);

    put({
      path: `/infra/quality-inspections/${qualityInspectionId}/devices/${deviceId}/deactivate`,
    }).then(() => {
      success("Dispositivo desativado com sucesso");
      setIsProcessing(false);
      refresh();
    });
  };

  return (
    <BurninTable cellSpacing={0} cellPadding={0} noDevices={devices.length === 0}>
      <thead>
        <th>UUID</th>
        <th>Posição</th>
        <th>Case</th>
        <th>Base</th>
        <th>Parafuso</th>
        <th>Fixação Base</th>
        <th>Adesivo</th>
        <th>QR-code</th>
        <th>Ligado</th>
        <th>Encaixe Mesa</th>
        <th></th>
        <th></th>
      </thead>
      <tbody>
        {devices &&
          devices
            ?.sort((a, b) => {
              if (!a.positionId) {
                return 1;
              }
              if (!b.positionId) {
                return -1;
              }
              return a.positionId - b.positionId;
            })
            .map((item: any, index: number) => (
              <Tr
                key={index}
                status={item.initialVisualInspectionStatus}
                positionId={item.positionId}
              >
                <td>{item?.activatorId.slice(-6) || "-"}</td>
                <td>{item.positionName}</td>
                <Td
                  visualInspection={item.initialVisualInspectionDetails?.case}
                >
                  {getFormattedStatus(
                    item.initialVisualInspectionDetails,
                    "case"
                  )}
                </Td>
                <Td
                  visualInspection={item.initialVisualInspectionDetails?.base}
                >
                  {getFormattedStatus(
                    item.initialVisualInspectionDetails,
                    "base"
                  )}
                </Td>
                <Td
                  visualInspection={item.initialVisualInspectionDetails?.screw}
                >
                  {getFormattedStatus(
                    item.initialVisualInspectionDetails,
                    "screw"
                  )}
                </Td>
                <Td
                  visualInspection={
                    item.initialVisualInspectionDetails?.baseFixing
                  }
                >
                  {getFormattedStatus(
                    item.initialVisualInspectionDetails,
                    "baseFixing"
                  )}
                </Td>
                <Td
                  visualInspection={
                    item.initialVisualInspectionDetails?.sticker
                  }
                >
                  {getFormattedStatus(
                    item.initialVisualInspectionDetails,
                    "sticker"
                  )}
                </Td>
                <Td
                  visualInspection={item.initialVisualInspectionDetails?.QRCode}
                >
                  {getFormattedStatus(
                    item.initialVisualInspectionDetails,
                    "QRCode"
                  )}
                </Td>
                <Td
                  visualInspection={
                    item.initialVisualInspectionDetails?.deviceTurnedOn
                  }
                >
                  {getFormattedStatus(
                    item.initialVisualInspectionDetails,
                    "deviceTurnedOn"
                  )}
                </Td>
                <Td
                  visualInspection={
                    item.initialVisualInspectionDetails?.tableFitting
                  }
                >
                  {getFormattedStatus(
                    item.initialVisualInspectionDetails,
                    "tableFitting"
                  )}
                </Td>
                <Td>
                  <Button
                    width="100px"
                    onClick={() => {
                      if (!item?.positionName) {
                        alterStatusButtonRefs.current[index]?.click();
                      } else {
                        handleDeactivateDevice(
                          item.qualityInspectionId,
                          item.id
                        );
                      }
                    }}
                    disabled={isProcessing}
                  >
                    {!item?.positionName ? "Ativar" : "Desativar"}
                  </Button>
                </Td>
                <td style={{ width: "165px" }}>
                  <DeviceContainer
                    inspectionId={inspectionId || ""}
                    deviceData={item}
                    type={InspectionStepsEnum.INITIAL_VISUAL}
                    handleNext={() => {}}
                    currentPosition={0}
                    refresh={refresh}
                  >
                    <Button
                      ref={(e) => (alterStatusButtonRefs.current[index] = e)}
                    >
                      <FaExchangeAlt />
                      <span> Alterar Status</span>
                    </Button>
                  </DeviceContainer>
                </td>
              </Tr>
            ))}
        {(!devices || devices?.length < 1) && (
          <TableEmpty>
            <FaFile style={{ fontSize: "24px" }} />
            <p style={{ margin: "0 0 0 8px" }}>Sem Dados</p>
          </TableEmpty>
        )}
      </tbody>
    </BurninTable>
  );
}
