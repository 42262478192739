import { cloneElement, ReactElement, useEffect, useState } from "react";
import { Modal } from "../../../components/Modal";
import { AddDevices } from "./AddDevices";
import {
  IBurninDetails,
  IDevices,
  InspectionStepsEnum,
  InspectionStatusEnum,
} from "../types";
import { ChangeStatus } from "./ChangeStatus";
import useApi from "../../../hooks/useApi";

type DetailsValues = {
  [k: string]: boolean;
};

interface IDeviceContainer {
  inspectionId: string;
  deviceData?: IDevices;
  burninDetails?: IBurninDetails;
  currentPosition: any;
  type: InspectionStepsEnum;
  handleNext: (statusPayload: {
    status: InspectionStatusEnum;
    details: DetailsValues;
  }) => void;
  children: ReactElement;
  refresh: () => void;
}

interface IavailableSlots {
  positionId: number;
  positionName: string;
  positioAvailable: boolean;
}

export function DeviceContainer({
  inspectionId,
  type,
  deviceData,
  burninDetails,
  handleNext,
  children,
  refresh,
}: IDeviceContainer) {
  const [show, setShow] = useState(false);
  const [lastCreate, setlastCreate] = useState<IDevices>();
  const [availableSlots, setAvailableSlots] = useState<IavailableSlots[]>([]);
  const closeModal = () => {
    setShow(false);
  };

  const burninCompanyId = process.env.REACT_APP_BURNIN_ID;

  const { request: requestDeviceSlots } = useApi({
    path: `/infra/quality-inspections/company/${Number(
      burninCompanyId
    )}/positions-status`,
  });

  function getDeviceSlots() {
    requestDeviceSlots({ method: "get" }).then(
      (response: IavailableSlots[]) => {
        setAvailableSlots(response);
      }
    );
  }

  useEffect(() => {
    if (show) {
      getDeviceSlots();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onCallNext = (deviceData: {
    status: InspectionStatusEnum;
    details: DetailsValues;
  }) => {
    if (lastCreate) {
      setlastCreate(undefined);
    }

    getDeviceSlots();
    handleNext(deviceData);
    setlastCreate(undefined);
    refresh();
  };

  const renderAddDevices = () => {
    return (
      <AddDevices
        inspectionId={inspectionId}
        closeModal={closeModal}
        eventCreate={(deviceData: IDevices) => {
          setlastCreate(deviceData);
          getDeviceSlots();
          refresh();
        }}
        availableSlots={availableSlots}
        companyId={Number(burninCompanyId)}
      />
    );
  };

  const renderChangeStatus = (
    deviceData?: IDevices | null,
    burninDetails?: IBurninDetails
  ) => {
    return (
      <ChangeStatus
        closeModal={closeModal}
        handleNext={onCallNext}
        deviceData={deviceData}
        burninDetails={burninDetails}
        inspectionId={inspectionId}
        availableSlots={availableSlots}
        type={type}
      />
    );
  };

  return (
    <>
      {cloneElement(children, { onClick: () => setShow(true) })}
      <Modal
        handleClose={() => setShow(!show)}
        show={show}
        changeFotter
        backdrop="static"
      >
        <>
          {lastCreate && renderChangeStatus(lastCreate)}
          {deviceData && renderChangeStatus(deviceData)}
          {burninDetails && renderChangeStatus(null, burninDetails)}
          {!lastCreate && !deviceData && !burninDetails && renderAddDevices()}
        </>
      </Modal>
    </>
  );
}
