import { useNavigate } from "react-router-dom";
import { ListDate } from "../../../components/List/ListDate";
import { ShowDate } from "../../../components/Show/ShowDate";
import { ExportQualityCSV } from "../ExportQualityCSV";
import { InspectionStepsEnum } from "../../../pages/QualityInspections/types";
import { ExportQualityHistoricCSV } from "../components/ExportQualityHistoricCSV";

const ViewDevices = ({
  qualityInspectionId
}: {
  qualityInspectionId: number;
}) => {
  const navigate = useNavigate();

  const goToInspectionDevices = () => {
    navigate(`/quality/inspection/${qualityInspectionId}`);
  };
  return (
    <p style={{ margin: 0 }} onClick={goToInspectionDevices}>
      Visualizar
    </p>
  );
};
export const InpectionsResource = {
  title: "Inspeções de Qualidade",
  urlPath: "/quality/inspections",
  apiPath: "/infra/quality-inspections",
  actions: {
    new: {
      isVisible: true
    },
    show: {
      isVisible: false
    },
    edit: {
      isVisible: true,
      edit: true,
      condition: (record: any) => record.currentStep !== "FINISHED"
    },
    delete: { isVisible: true },
    view: {
      name: "view",
      label: "Visualizar",
      type: "record",
      isVisible: true,
      condition: (record: any) => record.currentStep !== "FINISHED",
      handler: async (record: any) => {},
      modalComponent: (record: any) => (
        <ViewDevices qualityInspectionId={record.id} />
      )
    },
    exportCSV: {
      name: "exportCSV",
      condition: (record: any) => record.currentStep === "FINISHED",
      type: "record",
      label: "Exportar CSV",
      modalComponent: (record: any) => <ExportQualityCSV record={record} />,
      isVisible: true
    },
    exportHistoric: {
      name: "exportHistoric",
      condition: (record: any) => ![InspectionStepsEnum.INITIAL_VISUAL].includes(record.currentStep),
      type: "record",
      label: "Exportar Histórico",
      modalComponent: (record: any) => <ExportQualityHistoricCSV record={record} />,
    }
  },
  properties: {
    name: {
      id: "name",
      type: "TEXT",
      label: "Nome",
      isVisible: {
        list: true,
        filter: true,
        edit: true,
        new: true,
        show: true
      }
    },
    createdAt: {
      id: "createdAt",
      type: "DATE_PERIOD",
      label: "Data de criação",
      isVisible: {
        list: true,
        filter: true,
        show: true
      },
      component: {
        show: ({ record, label, id }: any) => (
          <ShowDate record={record} label={label} id={id} />
        ),
        list: ({ record, id }: any) => <ListDate record={record} id={id} />
      }
    },
    startDate: {
      id: "startDate",
      type: "DATE_TIME",
      label: "Início",
      isVisible: {
        list: true,
        filter: true,
        new: true,
        edit: true,
        show: true
      },
      component: {
        show: ({ record, label, id }: any) => (
          <ShowDate record={record} label={label} id={id} />
        ),
        list: ({ record, id }: any) => <ListDate record={record} id={id} />
      }
    },
    endDate: {
      id: "endDate",
      type: "DATE_TIME",
      label: "Fim",
      isVisible: {
        list: true,
        filter: true,
        new: true,
        edit: true,
        show: true
      },
      component: {
        show: ({ record, label, id }: any) => (
          <ShowDate record={record} label={label} id={id} />
        ),
        list: ({ record, id }: any) => <ListDate record={record} id={id} />
      }
    },
    deviceQuantity: {
      id: "deviceQuantity",
      type: "TEXT",
      label: "QTD",
      isVisible: {
        list: true,
        filter: false,
        new: true,
        show: true
      }
    },
    deviceBatch: {
      id: "deviceBatch",
      type: "TEXT",
      label: "Lote",
      isVisible: {
        list: true,
        filter: true,
        new: true,
        show: true
      }
    },
    deviceName: {
      id: "deviceName",
      type: "SELECT",
      label: "Produto",
      options: [
        { label: "Selecione", value: null },
        { label: "Spectra", value: "Spectra" }
      ],
      isVisible: {
        list: true,
        filter: true,
        new: true,
        show: true
      }
    },
    description: {
      id: "description",
      type: "TEXT",
      label: "Descrição",
      isVisible: {
        list: false,
        edit: true,
        new: true,
        show: true
      }
    },
    currentStep: {
      id: "currentStep",
      label: "Etapa",
      type: "SELECT",
      options: [
        { label: "Selecione", value: null },
        { label: "Inspeção visual", value: "INITIAL_VISUAL" },
        { label: "Burnin", value: "BURNIN_STATUS" },
        { label: "Última inspeção visual", value: "FINAL_VISUAL" },
        { label: "Embalando", value: "PACKING" },
        { label: "Finalizado", value: "FINISHED" }
      ],
      isVisible: {
        list: true,
        filter: true,
        new: false,
        edit: false,
        show: true
      }
    }
  }
};
