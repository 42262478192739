import { ListContainer } from "../styles";
import { ListProps } from "../types";

export const ListUserContact = ({ record }: ListProps) => {
  return (
    <ListContainer>
      <div>
        <span>ID: </span>
        <span>{record.cognitoId}</span>
      </div>
      <div>
        <span>Email: </span>
        <span>{record.email}</span>
      </div>
      <div>
        <span>Telefone: </span>
        <span>{record.phone}</span>
      </div>
      <div>
        <span>Nome: </span>
        <span>{record.name}</span>
      </div>
    </ListContainer>
  );
};
