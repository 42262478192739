import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import queryString from "query-string";

import { Modal } from "../../../../components/Modal";

import { ButtonCancel, Container, Footer, Title } from "./styles";
import { Button } from "../../../../components";
import useApi from "../../../../hooks/useApi";
import { useLocation } from "react-router-dom";

export const ModalExportMissingDataPositions = () => {
  const location = useLocation();

  const csvRef = useRef<any>(null);

  const { request } = useApi({
    path: "/monitorator/missing-data-positions?monitoringSolutionId=1",
  });

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState<String>("");
  const [data, setData] = useState<any>([]);
  const [completed, setCompleted] = useState(false);

  async function fetchPages() {
    setMessage("Buscando dados 0%...");
    setCompleted(false);

    const params = queryString.parse(location.search);

    let response, pagination;
    let page = 1;
    let accData: any = [];

    do {
      response = await request({
        method: "get",
        queryStringParameters: {
          page,
          limit: 100,
          ...params,
        },
      });

      pagination = response.pagination;
      page++;
      accData = [...accData, ...response.data];

      setMessage(
        `Buscando dados ${Math.trunc(
          (pagination.page / pagination.totalPages) * 100
        )}% ...`
      );
    } while (pagination.page < pagination.totalPages);

    const formattedData = accData.map((item: any) => {
      return {
        EMPRESA: item.companyName,
        UNIDADE: item.facilityName,
        EQUIPAMENTO: item.assetName,
        PONTO: item.positionName,
        "ID PONTO": item.positionId,
        UUID: item.activatorId,
        LINK: `${process.env.REACT_APP_RETINA_WEB_URL}/positions/${item.positionId}`,
        "TEM ROTAÇÃO?": item.pointRotation ? "SIM" : "NÃO",
        ROTACAO:
          item.pointRotation && String(item.pointRotation).replace(".", ","),
        "JANELA SPECTRAL": item.spectralWindow,
        "TEM HORIMETRO?": item.vibrationOff ? "SIM" : "NÃO",
        HORIMETRO:
          item.vibrationOff && String(item.vibrationOff).replace(".", ","),
        "TEM PASSA ALTA ACELERAÇÃO?":
          item.useHighPassRMS === "true" ? "SIM" : "NÃO",
        "PASSA ALTA ACELERAÇÃO":
          item.highPassRMSFrequency &&
          String(item.highPassRMSFrequency).replace(".", ","),
        "TEM PASSA BAIXA ACELERAÇÃO?":
          item.useLowPassRMS === "true" ? "SIM" : "NÃO",
        "PASSA BAIXA ACELERAÇÃO":
          item.lowPassRMSFrequency &&
          String(item.lowPassRMSFrequency).replace(".", ","),
        "TEM PASSA ALTA VELOCIDADE?":
          item.integralUseHighPassRMS === "true" ? "SIM" : "NÃO",
        "PASSA ALTA VELOCIDADE":
          item.integralHighPassRMSFrequency &&
          String(item.integralHighPassRMSFrequency).replace(".", ","),
        "TEM PASSA BAIXA VELOCIDADE?":
          item.integralUseLowPassRMS === "true" ? "SIM" : "NÃO",
        "PASSA BAIXA VELOCIDADE":
          item.integralLowPassRMSFrequency &&
          String(item.integralLowPassRMSFrequency).replace(".", ","),
        "TEM PASSA ALTA ENVELOPE?":
          item.envelopeUseHighPassFFT === "true" ? "SIM" : "NÃO",
        "PASSA ALTA ENVELOPE":
          item.envelopeHighPassFFTFrequency &&
          String(item.envelopeHighPassFFTFrequency).replace(".", ","),
        "TEM PASSA BAIXA ENVELOPE?":
          item.envelopeUseLowPassFFT === "true" ? "SIM" : "NÃO",
        "PASSA BAIXA ENVELOPE":
          item.envelopeLowPassFFTFrequency &&
          String(item.envelopeLowPassFFTFrequency).replace(".", ","),
      };
    });

    setMessage("Relatório pronto!");
    setCompleted(true);
    setData(formattedData);
  }

  useEffect(() => {
    if (show) {
      fetchPages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const date = new Date();

  return (
    <>
      <Button onClick={() => setShow(true)}>Exportar CSV</Button>
      <Modal
        handleClose={() => setShow(false)}
        show={show}
        confirmLabel="Baixar"
        size="lg"
        disabledConfirm={false}
        changeFotter
      >
        <Container>
          <Title>
            <strong>{`Exportando dados`}</strong>
          </Title>
          <p>{message}</p>
          {completed && (
            <CSVLink
              separator=";"
              style={{ display: "none" }}
              ref={csvRef}
              data={data}
              filename={`pontos-sem-dados-${date.getDay()}/${
                date.getMonth() + 1
              }.csv`}
              className=""
            ></CSVLink>
          )}
          <Footer>
            <div>
              <ButtonCancel onClick={() => setShow(false)}>
                Cancelar
              </ButtonCancel>
              <Button
                disabled={!completed}
                variant="primary"
                onClick={() => {
                  csvRef.current.link.click();
                }}
              >
                Baixar
              </Button>
            </div>
          </Footer>
        </Container>
      </Modal>
    </>
  );
};
