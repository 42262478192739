import { FaExchangeAlt, FaFile } from "react-icons/fa";
import { BurninTable, TableEmpty, Td, Tr } from "./styles";
import { IDevices, InspectionStepsEnum } from "./types";
import { DeviceContainer } from "./modals/DeviceContainer";
import { Button } from "../../components";
import { put } from "../../utils/api";

type ExpeditionInspectionProps = {
  devices: IDevices[];
  inspectionId: string;
  refresh: () => void;
};

export default function ExpeditionInspection({
  devices,
  inspectionId,
  refresh,
}: ExpeditionInspectionProps) {
  const getFormattedStatus = (details: any, key: string) => {
    if (!details) return "-";
    return details[key] ? "SIM" : "NÃO";
  };

  return (
    <BurninTable noDevices={devices.length === 0}>
      <thead>
        <th>UUID</th>
        <th>Posição</th>
        <th>Recarregado</th>
        <th>Parafusos on/off</th>
        <th>Parafusos m8</th>
        <th>Plástico Bolha</th>
        <th>Embalagem</th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </thead>
      <tbody>
        {devices?.map((item: IDevices, index: number) => (
          <Tr
            key={index}
            status={item.packingInspectionStatus}
            positionId={item.positionId}
          >
            <td>{item.activatorId.slice(-6) || "-"}</td>
            <td>{item.positionName}</td>
            <Td visualInspection={item.packingInspectionDetails?.recharged}>
              {getFormattedStatus(item.packingInspectionDetails, "recharged")}
            </Td>
            <Td visualInspection={item.packingInspectionDetails?.screwOnOff}>
              {getFormattedStatus(item.packingInspectionDetails, "screwOnOff")}
            </Td>
            <Td visualInspection={item.packingInspectionDetails?.screwM8}>
              {getFormattedStatus(item.packingInspectionDetails, "screwM8")}
            </Td>
            <Td visualInspection={item.packingInspectionDetails?.bubbleWrap}>
              {getFormattedStatus(item.packingInspectionDetails, "bubbleWrap")}
            </Td>
            <Td visualInspection={item.packingInspectionDetails?.packaging}>
              {getFormattedStatus(item.packingInspectionDetails, "packaging")}
            </Td>
            <td style={{ width: "165px" }}>
              <DeviceContainer
                inspectionId={inspectionId || ""}
                deviceData={item}
                type={InspectionStepsEnum.PACKING}
                handleNext={() => {}}
                currentPosition={0}
                refresh={refresh}
              >
                <Button>
                  <FaExchangeAlt />
                  <span> Alterar Status</span>
                </Button>
              </DeviceContainer>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </Tr>
        ))}
      </tbody>
      {(!devices || devices?.length < 1) && (
        <TableEmpty>
          <FaFile style={{ fontSize: "24px" }} />
          <p style={{ margin: "0 0 0 8px" }}>Sem Dados</p>
        </TableEmpty>
      )}
    </BurninTable>
  );
}
