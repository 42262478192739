import { ListLogoUrl } from "../../../components/List/LogoUrl/List";
import { RecordProps } from "../../../types/default";
import { ListPosition } from "../components/Position";

import { ModalExportMissingDataPositions } from "../modals/ExportMissingDataPositions";

export const MissingDataPositions = {
  title: "Spectras preenchidos",
  urlPath: "/monitorator/missing-data-positions",
  apiPath: "/monitorator/missing-data-positions?monitoringSolutionId=1",
  actions: {
    new: { isVisible: false },
    show: { isVisible: false },
    edit: { isVisible: false },
    delete: { isVisible: false },
    exportCSV: {
      name: "exportCSV",
      type: "resource",
      label: "Exportar CSV",
      modalComponent: () => <ModalExportMissingDataPositions />,
    },
  },
  properties: {
    logoUrl: {
      id: "logoUrl",
      type: "IMAGE",
      label: null,
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListLogoUrl record={record} id={id} />
        ),
      },
      isVisible: {
        list: true,
      },
    },
    position: {
      id: "position",
      type: "TEXT",
      label: "PONTO",
      isVisible: {
        list: true,
      },
      component: {
        list: ({ record, id }: RecordProps) => (
          <ListPosition record={record} id={id} />
        ),
      },
    },
    companyId: {
      id: "companyId",
      label: "EMPRESA",
      type: "SELECT_API",
      endpoint: "/companies-options",
      isVisible: {
        filter: true,
      },
    },
    facilityId: {
      id: "facilityId",
      label: "UNIDADE",
      type: "SELECT_API",
      endpoint: "/facilities-options",
      isVisible: {
        filter: true,
      },
    },
    monitoringSolutionId: {
      id: "monitoringSolutionId",
      label: "DISPOSITIVO",
      type: "SELECT",
      options: [{ label: "Spectra 1.0", value: 1 }],
      isVisible: {
        filter: true,
      },
    },
    spectralWindow: {
      id: "spectralWindow",
      label: "JANELA SPECTRAL",
      type: "SELECT",
      options: [{ label: "Spectra 1.0", value: 1 }],
      isVisible: {
        filter: true,
        list: true,
      },
    },
    pointRotation: {
      id: "pointRotation",
      type: "TEXT",
      label: "Rotação",
      isVisible: {
        list: true,
      },
    },
    hasRotation: {
      id: "hasRotation",
      label: "TEM ROTAÇÂO?",
      type: "SELECT",
      options: [
        { label: "Sim", value: 1 },
        { label: "Não", value: 0 },
      ],
      isVisible: {
        filter: true,
      },
    },
    hasVibrationOff: {
      id: "hasVibrationOff",
      label: "TEM HORIMETRO?",
      type: "SELECT",
      options: [
        { label: "Sim", value: 1 },
        { label: "Não", value: 0 },
      ],
      isVisible: {
        filter: true,
      },
    },
    vibrationOff: {
      id: "vibrationOff",
      label: "HORIMETRO",
      type: "TEXT",
      isVisible: {
        list: true,
      },
    },
    useHighPassRMS: {
      id: "useHighPassRMS",
      label: "TEM PASSA ALTA ACELERAÇÃO?",
      type: "SELECT",
      options: [
        { label: "Sim", value: 1 },
        { label: "Não", value: 0 },
      ],
      isVisible: {
        filter: true,
      },
    },
    highPassRMSFrequency: {
      id: "highPassRMSFrequency",
      label: "PASSA ALTA ACELERAÇÃO",
      type: "TEXT",
      isVisible: {
        list: true,
      },
    },
    useLowPassRMS: {
      id: "useLowPassRMS",
      label: "TEM PASSA BAIXA ACELERAÇÃO?",
      type: "SELECT",
      options: [
        { label: "Sim", value: 1 },
        { label: "Não", value: 0 },
      ],
      isVisible: {
        filter: true,
      },
    },
    lowPassRMSFrequency: {
      id: "lowPassRMSFrequency",
      label: "PASSA BAIXA ACELERAÇÃO",
      type: "TEXT",
      isVisible: {
        list: true,
      },
    },
    integralUseHighPassRMS: {
      id: "integralUseHighPassRMS",
      label: "TEM PASSA ALTA VELOCIDADE?",
      type: "SELECT",
      options: [
        { label: "Sim", value: 1 },
        { label: "Não", value: 0 },
      ],
      isVisible: {
        filter: true,
      },
    },
    integralHighPassRMSFrequency: {
      id: "integralHighPassRMSFrequency",
      label: "PASSA ALTA VELOCIDADE",
      type: "TEXT",
      isVisible: {
        list: true,
      },
    },
    integralUseLowPassRMS: {
      id: "integralUseLowPassRMS",
      label: "TEM PASSA BAIXA VELOCIDADE?",
      type: "SELECT",
      options: [
        { label: "Sim", value: 1 },
        { label: "Não", value: 0 },
      ],
      isVisible: {
        filter: true,
      },
    },
    integralLowPassRMSFrequency: {
      id: "integralLowPassRMSFrequency",
      label: "PASSA BAIXA VELOCIDADE",
      type: "TEXT",
      isVisible: {
        list: true,
      },
    },
    envelopeUseHighPassFFT: {
      id: "envelopeUseHighPassFFT",
      label: "TEM PASSA ALTA ENVELOPE?",
      type: "SELECT",
      options: [
        { label: "Sim", value: 1 },
        { label: "Não", value: 0 },
      ],
      isVisible: {
        filter: true,
      },
    },
    envelopeHighPassFFTFrequency: {
      id: "envelopeHighPassFFTFrequency",
      label: "PASSA ALTA ENVELOPE",
      type: "TEXT",
      isVisible: {
        list: true,
      },
    },
    envelopeUseLowPassFFT: {
      id: "envelopeUseLowPassFFT",
      label: "TEM PASSA ALTA ENVELOPE?",
      type: "SELECT",
      options: [
        { label: "Sim", value: 1 },
        { label: "Não", value: 0 },
      ],
      isVisible: {
        filter: true,
      },
    },
    envelopeLowPassFFTFrequency: {
      id: "envelopeLowPassFFTFrequency",
      label: "PASSA BAIXA ENVELOPE",
      type: "TEXT",
      isVisible: {
        list: true,
      },
    },
  },
};
