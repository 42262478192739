import { FaFile } from "react-icons/fa";
import { BurninTable, TableEmpty, Td, Tr } from "./styles";
import { IDevices, InspectionStepsEnum } from "./types";
import { DeviceContainer } from "./modals/DeviceContainer";
import { FaExchangeAlt } from "react-icons/fa";
import { Button } from "../../components";

type PostBurninInspectionProps = {
  devices: IDevices[];
  inspectionId: string;
  refresh: () => void;
};
export default function PostBurninInspection({
  devices,
  inspectionId,
  refresh,
}: PostBurninInspectionProps) {
  const getFormattedStatus = (details: any, key: string) => {
    if (!details) return "-";
    return details[key] ? "SIM" : "NÃO";
  };

  return (
    <BurninTable noDevices={devices.length === 0}>
      <thead>
        <th>UUID</th>
        <th>Posição</th>
        <th>Desligado</th>
        <th>Case</th>
        <th>Base</th>
        <th>Parafuso</th>
        <th>Fixação Base</th>
        <th>Adesivo</th>
        <th>QR-code</th>
        <th>Encaixe Mesa</th>
        <th></th>
      </thead>
      <tbody>
        {devices
          ?.map((item: any, index: number) => (
            <Tr
              key={index}
              status={item.finalVisualInspectionStatus}
              positionId={item.positionId}
            >
              <td>{item?.activatorId.slice(-6)}</td>
              <td>{item.positionName}</td>
              <Td
                visualInspection={
                  item.finalVisualInspectionDetails?.deviceTurnedOn
                }
              >
                {getFormattedStatus(
                  item.finalVisualInspectionDetails,
                  "deviceTurnedOn"
                )}
              </Td>
              <Td visualInspection={item.finalVisualInspectionDetails?.case}>
                {getFormattedStatus(item.finalVisualInspectionDetails, "case")}
              </Td>
              <Td visualInspection={item.finalVisualInspectionDetails?.base}>
                {getFormattedStatus(item.finalVisualInspectionDetails, "base")}
              </Td>
              <Td visualInspection={item.finalVisualInspectionDetails?.screw}>
                {getFormattedStatus(item.finalVisualInspectionDetails, "screw")}
              </Td>
              <Td
                visualInspection={item.finalVisualInspectionDetails?.baseFixing}
              >
                {getFormattedStatus(
                  item.finalVisualInspectionDetails,
                  "baseFixing"
                )}
              </Td>
              <Td visualInspection={item.finalVisualInspectionDetails?.sticker}>
                {getFormattedStatus(
                  item.finalVisualInspectionDetails,
                  "sticker"
                )}
              </Td>
              <Td visualInspection={item.finalVisualInspectionDetails?.QRCode}>
                {getFormattedStatus(
                  item.finalVisualInspectionDetails,
                  "QRCode"
                )}
              </Td>
              <Td
                visualInspection={
                  item.finalVisualInspectionDetails?.tableFitting
                }
              >
                {getFormattedStatus(
                  item.finalVisualInspectionDetails,
                  "tableFitting"
                )}
              </Td>
              <td style={{ width: "300px" }}>
                <DeviceContainer
                  inspectionId={inspectionId || ""}
                  deviceData={item}
                  type={InspectionStepsEnum.FINAL_VISUAL}
                  handleNext={() => {}}
                  currentPosition={0}
                  refresh={refresh}
                >
                  <Button>
                    <FaExchangeAlt />
                    <span> Alterar Status</span>
                  </Button>
                </DeviceContainer>
              </td>
            </Tr>
          ))}
      </tbody>
      {(!devices || devices?.length < 1) && (
        <TableEmpty>
          <FaFile style={{ fontSize: "24px" }} />
          <p style={{ margin: "0 0 0 8px" }}>Sem Dados</p>
        </TableEmpty>
      )}
    </BurninTable>
  );
}
