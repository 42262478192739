import { Dropdown } from "react-bootstrap";
import { BsFillGearFill } from "react-icons/bs";

import { ModalConfirm } from "../../ModalConfirm";

import { ColumnHeader } from "../styles";
import { Button } from "./styles";

export const createActionButton = (
  resource: any,
  location: string,
  navigate: any,
  request: any,
  refresh: any
) => {
  const validateCondition = (actionName: string, original: any): boolean => {
    if (resource?.actions?.[actionName]?.condition) {
      return resource.actions[actionName].condition(original);
    }
    return true;
  };
  const isVisibleShowAction =
    !resource.actions.show || resource.actions?.show?.isVisible;
  const isVisibleEditAction =
    !resource.actions.edit || resource.actions?.edit?.isVisible;
  const isVisibleDeleteAction =
    !resource.actions.delete || resource.actions?.delete?.isVisible;

  const customDeleteAction = resource.actions?.delete?.handler;

  const customRecordActions = Object.values(resource.actions).filter(
    (action: any) => action.type === "record"
  );

  const primaryKey: any = Object.values(resource.properties).find(
    (property: any) => property.isPrimaryKey
  );

  return {
    id: "action",
    accessor: "action",
    Header: () => (
      <ColumnHeader>
        <span>Ações</span>
      </ColumnHeader>
    ),
    width: "112px",
    Cell: ({ value, row }: any) => {
      const { original } = row;
      const fieldId = primaryKey ? primaryKey.id : "id";
      return (
        <Dropdown>
          <Button split={true}>
            <BsFillGearFill />
          </Button>
          <Dropdown.Menu>
            {isVisibleShowAction && validateCondition("show", original) && (
              <Dropdown.Item
                onClick={() =>
                  navigate(`${location}/show/${original[fieldId]}`)
                }
              >
                Ver
              </Dropdown.Item>
            )}
            {isVisibleEditAction && validateCondition("edit", original) && (
              <Dropdown.Item
                onClick={() =>
                  navigate(`${location}/edit/${original[fieldId]}`)
                }
              >
                Editar
              </Dropdown.Item>
            )}
            {customRecordActions.map((action: any, index: number) => {
              if (action.condition && !action.condition(original)) {
                return null;
              }

              const fieldId = primaryKey ? primaryKey.id : "id";

              const { modalComponent } = action;

              return (
                <Dropdown.Item
                  key={index}
                  onClick={() => {
                    if (action.component && !action.disableNavigate) {
                      return navigate(
                        `${location}/${original[fieldId]}/${action.name}`
                      );
                    }
                    if (action?.handler && !action.guard) {
                      action.handler(original);
                    }
                  }}
                >
                  {action.component ? (
                    action.label
                  ) : modalComponent ? (
                    modalComponent({
                      record: original,
                      id: original[fieldId],
                    })
                  ) : (
                    <ModalConfirm
                      message={action.guard}
                      handleConfirm={() => {
                        action.handler(original).then(() => {
                          refresh({})
                        });
                        return false;
                      }}
                    >
                      {action.label}
                    </ModalConfirm>
                  )}
                </Dropdown.Item>
              );
            })}
            {isVisibleDeleteAction && validateCondition("delete", original) && (
              <Dropdown.Item onClick={() => {}}>
                <ModalConfirm
                  message={`Tem certeza que deseja excluir?`}
                  handleConfirm={() => {
                    if (customDeleteAction) {
                      customDeleteAction(row.original).then(() => {
                        navigate(location);
                      });
                    } else {
                      request({
                        method: "del",
                        pathParameters: original[fieldId],
                      }).then(() => {
                        refresh({});
                        navigate(location);
                      });
                    }
                    return false;
                  }}
                >
                  {resource.actions?.delete?.label || "Excluir"}
                </ModalConfirm>
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      );
    },
  };
};
